.root {
}

.description {
	white-space: pre-wrap;
}

.root .title {
	overflow: hidden;
	text-overflow: ellipsis;
}

.mainInfo {
	display: flex;
	flex-wrap: wrap;
}

.root .logoContainer {
	margin-right: 24px;
}

.root .cell {
	flex-grow: 1;
	padding: 0;
	max-width: 100%;
}
